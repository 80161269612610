/* ##remfixer: 1080 */

.Toast {
  position: fixed;
  left: 50%;
  top: 50%;
  background-color: rgba(51, 51, 51, 0.85);
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  border-radius: 0.166667rem;
  color: #fff;
  font-size: 0.388889rem;
  font-weight: 500;
  padding: 0.25rem 0.740741rem;
  /* 		white-space: nowrap; */
  max-width: 80%;
  text-align: center;
  z-index: 100;
}

.fade-enter-active,
.fade-leave-active {
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}