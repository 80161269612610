body {
  -webkit-text-size-adjust: 100% !important;
}

.inline-middle {
  display: inline-block;
  line-height: 100%;
  vertical-align: -12.5%;
}

.clearfix:after {
  content: " ";
  display: block;
  font-size: 0;
  width: 0;
  height: 0;
  clear: both;
}

a {
  text-decoration: none;
  color: #fff;
}

ul,
li {
  list-style: none;
}

.divider {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.009259rem;
  background: rgba(255, 255, 255, 0.15);
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.hr {
  position: relative;
}

.hr:after {
  content: " ";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.009259rem;
  background: rgba(255, 255, 255, 0.15);
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.num {
  font-family: 'DINPro';
}

.none {
  display: none !important;
}

.hideText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (-webkit-min-device-pixel-ratio: 3) {
  .divider,
  .hr:after {
    -webkit-transform: scaleY(0.6666) translateZ(0);
    transform: scaleY(0.6666) translateZ(0);
  }
}

@media (-webkit-min-device-pixel-ratio: 4) {
  .divider,
  .hr:after {
    -webkit-transform: scaleY(0.5) translateZ(0);
    transform: scaleY(0.5) translateZ(0);
  }
}

.block_head {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  padding: 0 0.444444rem;
}

.block_head-box {
  position: relative;
  width: 100%;
  height: 0.833333rem;
  line-height: 0.833333rem;
}

.block_head-title {
  padding: 0.194444rem 0;
  line-height: 0.444444rem;
  font-size: 0.388889rem;
  color: #000;
  font-weight: 500;
}

.block_head-more {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.388889rem;
  color: #FC5B23;
}

.row {
  padding: 0 0.444444rem;
}

html,
body {
  width: 100%;
  height: 100%;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  font-family: "思源黑体", "Microsoft Yahei", Arial, Helvetica, sans-serif, "\u5b8b\u4f53";
  max-width: 640px;
  margin: 0 auto;
}

* {
  margin: 0;
  border: 0;
  padding: 0;
  outline: 0;
  font-family: "思源黑体", "Microsoft Yahei", Arial, Helvetica, sans-serif, "\u5b8b\u4f53";
}

#app {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  font-size: 14px;
  min-height: 100%;
  padding-bottom: 4.444444rem;
}