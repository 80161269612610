/* ##remfixer: 1080 */

.loading-inner {
  position: absolute;
  top: 45%;
  left: 50%;
  z-index: 1000;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  font-size: 0.388889rem;
}

.loading-inner .loading-img {
  width: 0.666666rem;
  margin-left: 30%;
  height: 0.666666rem;
  margin-bottom: 0.25rem;
}

.fade-enter-active,
.fade-leave-active {
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}