/* ##remfixer: 1080 */

.Footer {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  height: 2.592593rem;
  padding: 0.833333rem 0 0.5rem;
  background-color: #353C50;
  bottom: 0;
  left: 0;
  z-index: 10;
}

.Footer .shares {
  width: 100%;
  margin-bottom: 0.555556rem;
  font-size: 0;
  text-align: center;
}

.Footer .shares .share_blk {
  display: inline-block;
  width: 1.111111rem;
  height: 1.111111rem;
  margin: 0 0.398148rem;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.Footer .share_weixin {
  background-image: url('../assets/images/wechat.png');
  vertical-align: top;
}

.Footer .share_weibo {
  background-image: url('../assets/images/weibo.png');
}

.Footer .share_qq {
  background-image: url('../assets/images/qq.png');
}

.Footer .links {
  width: 100%;
  line-height: 0.333333rem;
  height: 0.333333rem;
  margin-bottom: 0.388889rem;
  font-size: 0;
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  vertical-align: middle;
}

.Footer .links .link {
  font-size: 0.333333rem;
  vertical-align: middle;
}

.Footer .links .gap {
  display: inline-block;
  width: 1px;
  height: 0.277778rem;
  background-color: rgba(255, 255, 255, 0.8);
  margin: 0 0.277778rem;
  vertical-align: middle;
}

.Footer .copyright {
  width: 100%;
  line-height: 0.388889rem;
  font-size: 0.277778rem;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
}

.Footer .copyright .link {
  color: rgba(255, 255, 255, 0.5);
}