/* ##remfixer: 1080 */

.Top {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  width: 100%;
  height: 1.685185rem;
  padding: 0 0.462963rem;
  border-bottom: 0.018519rem solid rgba(0, 0, 0, 0.1);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.Top_logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 0.925926rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.Top_logo img {
  float: left;
  width: 0.925926rem;
  height: 0.925926rem;
}

.Top_logo p {
  float: left;
  font-size: 0.388889rem;
  color: rgba(0, 0, 0, 0.9);
  margin-left: 0.277778rem;
}

.Top .down_load {
  width: 2.962963rem;
  height: 1.111111rem;
  background-size: 100% 100%;
}