.progress {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 0.018519rem;
  width: 0%;
  -webkit-transition: width 0.2s, opacity 0.4s;
  transition: width 0.2s, opacity 0.4s;
  opacity: 1;
  background-color: #efc14e;
  z-index: 999999;
}