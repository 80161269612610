/* ##remfixer: 1080 */

.qrcode {
  position: relative;
  width: 2.296296rem;
  padding-top: 0.185185rem;
  background-color: #fff;
}

.qrcode img {
  display: block;
  width: 1.777778rem;
  height: 1.796296rem;
  margin: 0 auto;
}

.qrcode p {
  width: 100%;
  font-size: 0.25rem;
  color: #353c50;
  letter-spacing: 0.011111rem;
  line-height: 0.388889rem;
  text-align: center;
}

.qrcode:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-width: 0.185185rem 0.185185rem 0;
  border-style: solid;
  border-color: #fff transparent transparent;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 100%);
  transform: translate(-50%, 100%);
}